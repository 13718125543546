import {
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  ProgressBar,
  Row,
  Col,
  Table,
  Card,
  Container,
  Button,
} from "react-bootstrap";

// chartjs plugin
import { Bar, Line } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";
// ReactApexChart
import ReactApexChart from "react-apexcharts";

// material ui
import CircularProgress from "@mui/material/CircularProgress";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { QrReader } from "react-qr-reader";
import ListGroup from "react-bootstrap/ListGroup";
import Axios from "axios";
import { Url } from "../App";
import "../App.css";

function QrReaderPanel() {
  const [data, setData] = useState("No result");
  const [resul, setResul] = useState("");
  const [verification, setVerification] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useContext(Url);
  const [wait, setWait] = useState(false);
  const [lis, setLis] = useState([]);
  const [showQr, setShowQr] = useState(false);

  useEffect(() => {
    if (data == "hello world") {
      alert("Hello to the World");
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (verification == true) {
        setVerification(false);
      }
      if (resul != "") {
        setResul("");
      }
    }, 5000);
  }, [verification, resul]);

  useEffect(() => {
    if (wait == true) {
      setTimeout(() => setWait(false), 5000);
    }
  }, [wait]);

  useEffect(() => {
    console.log(wait + "this is wait");
    console.log(showQr + "this is showqr code");
    console.log(lis);
  }, [wait, showQr]);

  const verify = async (a) => {
    await Axios.post(url + "/api/verify", {
      code: a,
    }).then((response) => {
      if (response.data.length > 0) {
        setVerification(true);
        setName(response.data[0].name);
        claimed(a);
        setWait(true);
        setShowQr(false);
        setLis([...lis, { name: response.data[0].name, result: "Pass" }]);
      } else {
        console.log(a);
        setVerification(false);
        setResul("Invalid Code");
        setLis([...lis, { name: "No user", result: "Invalid Code" }]);
      }
    });
  };

  const claimed = async (b) => {
    await Axios.post(url + "/api/claimed", {
      code: b,
    }).then((response) => {
      console.log("Response has been claimed");
    });
  };

  const allow = () => {
    return;
  };
  return (
    <>
      {/* <!--Row--> */}
      <Row className="row-sm">
        <Col fluid="xl">
          {/* <!--Row --> */}

          {/* <!--Row--> */}
          <Row className="row-sm">
            {/* <!-- col end --> */}
            <Col lg={12}>
              <Card className="custom-card mg-b-20">
                <Card.Body>
                  <Card.Header className="card-header border-bottom-0 pt-0 ps-0 pe-0 d-flex">
                    <div>
                      <label className="main-content-label mb-2">
                        Qr Reader
                      </label>
                      <span className="d-block tx-12 mb-3 text-muted">
                        Read Fight Capital Holders QR Codes.{" "}
                        <button
                          className="btn btn-primary"
                          onClick={() => window.location.reload(false)}
                        >
                          Refresh
                        </button>
                      </span>
                    </div>
                  </Card.Header>

                  {showQr == true ? (
                    <QrReader
                      onResult={(result, error) => {
                        if (!!result) {
                          setData(result?.text);
                          verify(result?.text);
                        }

                        if (!!error) {
                          console.info(error);
                        }
                      }}
                      constraints={{
                        facingMode: "environment",
                      }}
                      className="qrReader"
                    />
                  ) : (
                    <ListGroup style={{ color: "white", textAlign: "center" }}>
                      <ListGroup.Item
                        style={{
                          color: "white",
                          backgroundColor: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowQr(true)}
                      >
                        <h2>Scan</h2>
                      </ListGroup.Item>
                    </ListGroup>
                  )}

                  <ListGroup style={{ color: "white", textAlign: "center" }}>
                    {verification == true && data != "" ? (
                      <ListGroup.Item
                        style={{ color: "white", backgroundColor: "green" }}
                      >
                        <h2>Verified {name.toUpperCase()}</h2>
                      </ListGroup.Item>
                    ) : resul != "" && data != "" ? (
                      <ListGroup.Item
                        style={{ color: "white", backgroundColor: "red" }}
                      >
                        <h2>Invalid Code</h2>
                      </ListGroup.Item>
                    ) : showQr == true ? (
                      <ListGroup.Item
                        style={{
                          color: "white",
                          backgroundColor: "grey",
                          height: "60px",
                        }}
                      >
                        <h2>Scanning</h2>
                      </ListGroup.Item>
                    ) : (
                      ""
                    )}
                  </ListGroup>
                  <ListGroup style={{ color: "white", textAlign: "center" }}>
                    {lis.length > 0 &&
                      lis.map((a) => {
                        a.result == "Pass" ? (
                          <ListGroup.Item
                            style={{ color: "white", backgroundColor: "green" }}
                          >
                            <h2>
                              {a.name} | {a.result}
                            </h2>
                          </ListGroup.Item>
                        ) : (
                          <ListGroup.Item
                            style={{ color: "white", backgroundColor: "Red" }}
                          >
                            <h2>
                              {a.name} | {a.result}
                            </h2>
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            {/* <!-- col end --> */}
          </Row>
          {/* <!-- Row end --> */}
        </Col>
        {/* <!-- col end --> */}
        {/* <!-- col end --> */}
      </Row>
      {/* <!-- Row end --> */}
    </>
  );
}

QrReaderPanel.propTypes = {};

QrReaderPanel.defaultProps = {};

export default QrReaderPanel;
