import {
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  ProgressBar,
  Row,
  Col,
  Table,
  Card,
  Container,
  Button,
} from "react-bootstrap";

// chartjs plugin
import { Bar, Line } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";
// ReactApexChart
import ReactApexChart from "react-apexcharts";

// material ui
import CircularProgress from "@mui/material/CircularProgress";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { QrReader } from "react-qr-reader";
import ListGroup from "react-bootstrap/ListGroup";
import Axios from "axios";
import { Url } from "../App";
import "../App.css";

function Viewers() {
  const [data, setData] = useState("No result");
  const [resul, setResul] = useState("");
  const [verification, setVerification] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useContext(Url);
  const [wait, setWait] = useState(false);
  const [lis, setLis] = useState([]);
  const [showQr, setShowQr] = useState(false);
  const [ipList, setIpList] = useState([]);

  useEffect(() => {
    if (data == "hello world") {
      alert("Hello to the World");
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (verification == true) {
        setVerification(false);
      }
      if (resul != "") {
        setResul("");
      }
    }, 5000);
  }, [verification, resul]);

  useEffect(() => {
    if (wait == true) {
      setTimeout(() => setWait(false), 5000);
    }
  }, [wait]);

  useEffect(() => {
    console.log(wait + "this is wait");
    console.log(showQr + "this is showqr code");
    console.log(lis);
  }, [wait, showQr]);

  useEffect(() => {
    const a = async () =>
      await Axios.get(url + "/api/getip").then((response) => {
        setIpList(response.data);
      });
    a();
  }, []);

  const verify = async (a) => {
    await Axios.post(url + "/api/verify", {
      code: a,
    }).then((response) => {
      if (response.data.length > 0) {
        setVerification(true);
        setName(response.data[0].name);
        claimed(a);
        setWait(true);
        setShowQr(false);
        setLis([...lis, { name: response.data[0].name, result: "Pass" }]);
      } else {
        console.log(a);
        setVerification(false);
        setResul("Invalid Code");
        setLis([...lis, { name: "No user", result: "Invalid Code" }]);
      }
    });
  };

  const claimed = async (b) => {
    await Axios.post(url + "/api/claimed", {
      code: b,
    }).then((response) => {
      console.log("Response has been claimed");
    });
  };

  const allow = () => {
    return;
  };
  return (
    <>
      {/* <!--Row--> */}
      <Row className="row-sm">
        <Col fluid="xl">
          {/* <!--Row --> */}

          {/* <!--Row--> */}
          <Row className="row-sm">
            {/* <!-- col end --> */}
            <Col lg={12}>
              <Card className="custom-card mg-b-20">
                <Card.Body>
                  <Card.Header className="card-header border-bottom-0 pt-0 ps-0 pe-0 d-flex">
                    <div>
                      <label className="main-content-label mb-2">
                        Site Viewers
                      </label>
                      <span className="d-block tx-12 mb-3 text-muted">
                        List of Site Viewers
                      </span>
                    </div>
                  </Card.Header>

                  <ListGroup>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>IP</th>
                          <th>City</th>
                          <th>Date/Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ipList.map((a) => (
                          <tr>
                            <td>{a.ip}</td>
                            <td>{a.city}</td>
                            <td>{a.dtime}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            {/* <!-- col end --> */}
          </Row>
          {/* <!-- Row end --> */}
        </Col>
        {/* <!-- col end --> */}
        {/* <!-- col end --> */}
      </Row>
      {/* <!-- Row end --> */}
    </>
  );
}

Viewers.propTypes = {};

Viewers.defaultProps = {};

export default Viewers;
