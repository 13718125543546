import {
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  ProgressBar,
  Row,
  Col,
  Table,
  Card,
  Container,
  Button,
} from "react-bootstrap";

// chartjs plugin
import { Bar, Line } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";
// ReactApexChart
import ReactApexChart from "react-apexcharts";

// material ui
import CircularProgress from "@mui/material/CircularProgress";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { QrReader } from "react-qr-reader";
import ListGroup from "react-bootstrap/ListGroup";
import Axios from "axios";
import { Url } from "../App";
import "../App.css";

function CodeReader({ names, code }) {
  const [data, setData] = useState("No result");
  const [resul, setResul] = useState("");
  const [verification, setVerification] = useState(false);
  const [name, setName] = useState(names);
  const [url, setUrl] = useContext(Url);
  const [wait, setWait] = useState(false);
  const [lis, setLis] = useState([]);
  const [codes, setCodes] = useState(code);
  const [checker, setChecker] = useState([]);
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (data == "hello world") {
      alert("Hello to the World");
    }
  }, [data]);

  const getVerification = async (a) => {
    await Axios.post(url + "/api/getverification", {
      name: name,
    }).then((response) => {
      if (response.data.length > 0) {
        setChecker(response.data[0]);
        console.log(response.data[0]);
      } else {
        console.log("No data Found.");
      }
    });
  };

  useEffect(() => {
    getVerification();
  }, [change]);
  useEffect(() => {
    setTimeout(() => {
      if (verification == true) {
        setVerification(false);
      }
      if (resul != "") {
        setResul("");
      }
    }, 5000);
  }, [verification, resul]);

  useEffect(() => {
    if (wait == true) {
      setTimeout(() => setWait(false), 5000);
    }
  }, [wait]);

  const verify = async (a) => {
    await Axios.post(url + "/api/verify", {
      code: a,
    }).then((response) => {
      if (response.data.length > 0) {
        setVerification(true);
        setName(response.data[0].name);
        claimed(a);
        setWait(true);
        setLis([...lis, { name: response.data[0].name, result: "Pass" }]);
      } else {
        console.log(a);
        setVerification(false);
        setResul("Invalid Code");
        setLis([...lis, { name: "No user", result: "Invalid Code" }]);
      }
    });
  };

  const claimed = async (b) => {
    await Axios.post(url + "/api/claimed", {
      code: b,
    }).then((response) => {
      console.log("Response has been claimed");
    });
  };

  const changeClaim = async (b) => {
    await Axios.post(url + "/api/changeclaim", {
      name: name,
    }).then((response) => {
      console.log("Response has been claimed");
      setChange(!change);
    });
  };
  const allow = () => {
    return;
  };
  return (
    <>
      {/* <!--Row--> */}
      <Row className="" style={{ marginTop: "10px" }}>
        <Col>
          {/* <!--Row --> */}

          {/* <!--Row--> */}
          <Row className="">
            {/* <!-- col end --> */}
            <Col>
              <Card className="custom-card mg-b-20">
                <Card.Body>
                  <ListGroup style={{ color: "white", textAlign: "center" }}>
                    <ListGroup.Item
                      style={{
                        color: "white",
                        backgroundColor: "grey",

                        marginBottom: "10px",
                      }}
                    >
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum
                      </p>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>

            {/* <!-- col end --> */}
          </Row>
          <Row className="">
            {/* <!-- col end --> */}
            <Col>
              <Card className="custom-card mg-b-20">
                <Card.Body>
                  <ListGroup style={{ color: "white", textAlign: "center" }}>
                    {checker.verified == "true" ? (
                      <ListGroup.Item
                        style={{
                          color: "white",
                          backgroundColor: "red",
                          cursor: "pointer",
                          marginBottom: "10px",
                        }}
                        onClick={() => alert("hey there deliliah")}
                      >
                        <h2>Claimed {name?.toUpperCase()}</h2>
                      </ListGroup.Item>
                    ) : checker.verified == "false" ? (
                      <ListGroup.Item
                        style={{
                          color: "white",
                          backgroundColor: "green",
                          cursor: "pointer",
                        }}
                        onClick={changeClaim}
                      >
                        <h2>Claim {name?.toUpperCase()}</h2>
                      </ListGroup.Item>
                    ) : (
                      <ListGroup.Item
                        style={{
                          color: "white",
                          backgroundColor: "grey",
                        }}
                      >
                        <h2>Loading</h2>
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>

            {/* <!-- col end --> */}
          </Row>
          {/* <!-- Row end --> */}
        </Col>
        {/* <!-- col end --> */}
        {/* <!-- col end --> */}
      </Row>
      {/* <!-- Row end --> */}
    </>
  );
}

CodeReader.propTypes = {};

CodeReader.defaultProps = {};

export default CodeReader;
