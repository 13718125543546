import React, { useContext, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import ListGroup from "react-bootstrap/ListGroup";
import Axios from "axios";
import { Url } from "../App";
function ReadQr() {
  const [data, setData] = useState("No result");
  const [resul, setResul] = useState("");
  const [verification, setVerification] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useContext(Url);
  const [wait, setWait] = useState(false);

  useEffect(() => {
    if (data == "hello world") {
      alert("Hello to the World");
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (verification == true) {
        setVerification(false);
      }
      if (resul != "") {
        setResul("");
      }
    }, 5000);
  }, [verification, resul]);

  useEffect(() => {
    if (wait == true) {
      setTimeout(() => setWait(false), 5000);
    }
  }, [wait]);

  const verify = async (a) => {
    await Axios.post(url + "/api/verify", {
      code: a,
    }).then((response) => {
      if (response.data.length > 0) {
        setVerification(true);
        setName(response.data[0].name);
        claimed(a);
        setWait(true);
      } else {
        console.log(a);
        setVerification(false);
        setResul("Invalid Code");
      }
    });
  };

  const claimed = async (b) => {
    await Axios.post(url + "/api/claimed", {
      code: b,
    }).then((response) => {
      console.log("Response has been claimed");
    });
  };

  /*
  const verified = async (a) => {
    await Axios.post(url + "/api/verified", {
      code: a,
    }).then((response) => {
      if (response.data.length > 0) {
        setResul("Verified " + response.data[0].name);
        claimed(a);
      } else {
        console.log("this is a");
        console.log(a);
        setResul("Invalid Code");
      }
    });
  };
*/
  return (
    <div className="App">
      <h1>Bar Ticket Reader</h1>

      <div className="camera">
        <button
          className="btn btn-primary"
          onClick={() => window.location.reload(false)}
        >
          Refresh
        </button>
        {wait == false ? (
          <QrReader
            onResult={(result, error) => {
              if (!!result) {
                setData(result?.text);
                verify(result?.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            constraints={{
              facingMode: "environment",
            }}
          />
        ) : (
          <>
            <QrReader
              onResult={(result, error) => {
                if (!!result) {
                  console.log("ok");
                }
              }}
              constraints={{
                facingMode: "environment",
              }}
            />
          </>
        )}
        <ListGroup style={{ color: "white", textAlign: "center" }}>
          {verification == true && data != "" ? (
            <ListGroup.Item
              style={{ color: "white", backgroundColor: "green" }}
            >
              <h2>Verified {name.toUpperCase()}</h2>
            </ListGroup.Item>
          ) : (
            resul != "" &&
            data != "" && (
              <ListGroup.Item
                style={{ color: "white", backgroundColor: "red" }}
              >
                <h2>Invalid Code</h2>
              </ListGroup.Item>
            )
          )}
        </ListGroup>
      </div>
    </div>
  );
}

export default ReadQr;
