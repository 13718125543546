import logo from "./logo.svg";

import React, { createContext, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import ListGroup from "react-bootstrap/ListGroup";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Home from "./routes/Home";
import CreateQr from "./routes/CreateQr";
import ReadQr from "./routes/ReadQr";
import Generator from "./routes/Generator";
import Axios from "axios";
import { GetCodes, getCodes } from "./routes/hooks";
import Dashboard from "./routes/Test";
import QrReaderPanel from "./routes/QrReaderPanel";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import CodeReader from "./routes/CodeReader";
import Viewers from "./routes/Viewers";

export const Generated = createContext();
export const Lis = createContext();
export const Name = createContext();
export const Codes = createContext();
export const Change = createContext();
export const Url = createContext();
function App() {
  const [gen, setGen] = useState("No data Found");
  const [lis, setLis] = useState([]);
  const [name, setName] = useState("");
  const [codes, setCodes] = useState([]);
  const [change, setChange] = useState(false);
  const [url, setUrl] = useState("https://verifier.brunowalter.repl.co");

  const [ip, setIP] = useState("");
  const [city, setCity] = useState("");
  const [dtime, setDtime] = useState("");
  const [check, setCheck] = useState(false);
  const [linkUrls, setLinkUrls] = useState([]);

  useEffect(() => {}, []);

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
    var currentdate = new Date();
    var datetime =
      currentdate.getMonth() +
      1 +
      "/" +
      currentdate.getDay() +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      (currentdate.getHours() < 10
        ? "0" + currentdate.getHours()
        : currentdate.getHours()) +
      ":" +
      (currentdate.getMinutes() < 10
        ? "0" + currentdate.getMinutes()
        : currentdate.getMinutes()) +
      ":" +
      (currentdate.getSeconds() < 10
        ? "0" + currentdate.getSeconds()
        : currentdate.getSeconds());
    setDtime(datetime);
  }, []);

  useEffect(() => {
    if (ip != "" && (city != "") & (dtime != "") & (check != true)) {
      visitor();
    }
  }, [ip, city, dtime]);

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get("https://geolocation-db.com/json/");
    console.log(res.data.IPv4);
    console.log(res.data);
    setIP(res.data.IPv4);
    setCity(res.data.city + ", " + res.data.state);
  };

  const visitor = async (a) => {
    await Axios.post(url + "/api/visitor", {
      city: city,
      ip: ip,
      date: dtime,
    }).then((response) => {
      if (response.data.length > 0) {
        console.log("");
        setCheck(true);
      } else {
        console.log("");
        setCheck(true);
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const a = async () =>
        await Axios.get(url + "/api/get").then((response) => {
          setCodes(response.data);
        });
      a();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const a = async () =>
      await Axios.get(url + "/api/get").then((response) => {
        setCodes(response.data);
        setLinkUrls(response.data);
      });
    a();
  }, []);
  return (
    <div className="App">
      <Navbar bg="light" variant="light" expand="lg" style={{ color: "black" }}>
        <Container>
          <Navbar.Brand href="#home">Fight Capital Portal</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">
                <Link to="/">Home</Link>
              </Nav.Link>
              <Nav.Link href="#link">
                <Link to="/create">Create</Link>{" "}
              </Nav.Link>

              <Nav.Link href="#link">
                <Link to="/readadmin">Read 2.0</Link>{" "}
              </Nav.Link>
              <Nav.Link href="#link">
                <Link to="/viewers">Viewers</Link>{" "}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <nav></nav>
      <Url.Provider value={[url, setUrl]}>
        <Change.Provider value={[change, setChange]}>
          <Codes.Provider value={[codes, setCodes]}>
            <Generated.Provider value={[gen, setGen]}>
              <Lis.Provider value={[lis, setLis]}>
                <Name.Provider value={[name, setName]}>
                  <Routes>
                    {linkUrls.map((a) => (
                      <Route
                        path={"/" + a.code}
                        element={<CodeReader names={a.name} code={a.code} />}
                      />
                    ))}
                    <Route path="/codereader" element={<CodeReader />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/create" element={<CreateQr />} />
                    <Route path="/test" element={<Dashboard />} />
                    <Route path="/readadmin" element={<QrReaderPanel />} />
                    <Route path="/read" element={<ReadQr />} />
                    <Route path="/gen" element={<Generator />} />
                    <Route path="/viewers" element={<Viewers />} />
                    <Route path="*" element={<Home />} />
                  </Routes>
                </Name.Provider>
              </Lis.Provider>
            </Generated.Provider>
          </Codes.Provider>
        </Change.Provider>
      </Url.Provider>
    </div>
  );
}

export default App;
